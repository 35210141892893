export const b64_to_utf8 = (str) => {
    try {
        return decodeURIComponent(atob(str).split('').map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''));
    } catch (e) {
        console.error("Error decoding base64 string:", e);
        return str;
    }
};

export const utf8_to_b64 = (str) => {
    try {
        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (_, p1) => String.fromCharCode('0x' + p1)));
    } catch (e) {
        console.error("Error encoding string to base64:", e);
        return str;
    }
};