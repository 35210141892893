import {Command} from '@ckeditor/ckeditor5-core';

export default class SpanCommand extends Command {
    refresh() {
        const {model} = this.editor;
        const {selection} = model.document;

        // this.value = null;
        this.isEnabled = model.schema.checkAttributeInSelection(selection, 'span');
    }

    getDesmosText(editor, data = null, draggedElementUUID = null) {
        const {model} = editor;
        const {selection} = model.document;
        let editorModel = editor.model;
        let editorDocument = editorModel.document;

        var desmosText = angular.element(document).injector().get('$rootScope').copyTexString;
        var data_copy = data;
        if (typeof (window.desmosCalculator) !== 'object') {
            return;
        }

        var latex2png = function (editor, texstring, draggedElementUUID) {
            window.MathJax = {
                jax: ["input/TeX", "output/SVG"],
                extensions: ["tex2jax.js", "MathMenu.js", "MathZoom.js"],
                showMathMenu: false,
                showProcessingMessages: false,
                messageStyle: "none",
                SVG: {
                    useGlobalCache: false
                },
                TeX: {
                    extensions: ["AMSmath.js", "AMSsymbols.js", "autoload-all.js"]
                },
                AuthorInit: function () {
                    var input = texstring.filter(Boolean);
                    input.map(function (item) {
                        try {
                            console.log("Rendering expression: " + item);
                            let uuid = angular.createUUID();
                            var html = '<img id="' + uuid + '" class="image-from-calculator preload" alt="' + uuid + '" src="../images/desmosLoader.gif" style="border:1px solid #ececec; padding: 2px; background-color: #fff">';

                            const newElement = editor.data.processor.toView(html);
                            newElement.getChild(0)._setAttribute('data-latex', window.btoa(item));
                            const modelFragment = editor.data.toModel(newElement);
                            //
                            modelFragment.className = 'image-from-calculator';

                            if (draggedElementUUID) {
                                uuid = draggedElementUUID;
                            }

                            model.change(writer => {

                                const {end: positionAfter} = model.insertContent(
                                    writer.createElement('imageInline', {
                                        src: '../images/desmosLoader.gif',
                                        dSource: window.btoa(item),
                                        alt: uuid,
                                        class: 'image-from-calculator preload',
                                        uuid: uuid
                                    })
                                );

                                writer.setSelection(positionAfter);
                                writer.removeSelectionAttribute('span');
                            });

                            editor.execute('enter');
                            MathJax.Hub.Register.StartupHook("End", function () {
                                var wrapper = document.createElement("div");
                                wrapper.innerHTML = '$$' + item + '$$';
                                var output = {svg: "", img: ""};
                                MathJax.Hub.Queue(["Typeset", MathJax.Hub, wrapper]);
                                MathJax.Hub.Queue(function () {
                                    console.log("Rendering mathJax output to PNG", item);

                                    var mjOut = wrapper.getElementsByTagName("svg")[0];
                                    mjOut.setAttribute("xmlns", "http://www.w3.org/2000/svg");

                                    var exWidth = parseFloat(mjOut.getAttribute("width"));
                                    var exHeight = parseFloat(mjOut.getAttribute("height"));
                                    var exToPxFactor = 8;
                                    var pxWidth = exWidth * exToPxFactor;
                                    var pxHeight = exHeight * exToPxFactor;


                                    mjOut.setAttribute("width", (pxWidth * 2) + "px");
                                    mjOut.setAttribute("height", (pxHeight * 2) + "px");

                                    var canvas = document.createElement('canvas');
                                    var opts = {
                                        renderCallback: function () {
                                            var currentElementLoading = document.querySelector('span[data-source="' + window.btoa(item) + '"]');

                                            if (currentElementLoading) {
                                                const findElementRecursively = (node, targetDataSource) => {
                                                    if (node._attrs && node._attrs.get('dSource') === targetDataSource &&  node._attrs.get('uuid') === uuid) {
                                                        return node;
                                                    }
                                                    if (node._children && Array.isArray(node._children._nodes)) {
                                                        for (let child of node._children._nodes) {
                                                            let found = findElementRecursively(child, targetDataSource, uuid);
                                                            if (found) return found;
                                                        }
                                                    }
                                                    return null;
                                                };

                                                model.change(writer => {
                                                    let node = findElementRecursively(editorDocument.getRoot(), window.btoa(item));
                                                    if (node) {
                                                        writer.setAttributes({
                                                            src: canvas.toDataURL(),
                                                            width: pxWidth,
                                                            height: pxHeight,
                                                            class: 'ck-math-widget',
                                                            uuid: uuid
                                                        }, node);
                                                    }
                                                });
                                            } else {
                                                latex2png(editor, texstring, draggedElementUUID);
                                            }
                                            mjOut.remove();
                                        }
                                    };
                                    canvg(canvas, mjOut.outerHTML, opts);

                                });
                            });
                        } catch (e) {
                            console.error('Error Rendering expression: ' + item + ' -->' + e, e);
                        }
                    });
                }
            };
        };

        var latexSpan = function (latex, id) {
            var error = window.desmosCalculator.controller.model._evaluations[id].error !== undefined;
            var evaluation = window.desmosCalculator.controller.model._evaluations[id].value;
            var evaluationPowerOfTen = Math.abs(evaluation).toExponential().split("e");
            var splitEvaluation = [(evaluation > 0) ? Math.floor(evaluation) : Math.ceil(evaluation), evaluation % 1];
            var roundVal = 10 - splitEvaluation[0].toString().length;
            var base = parseFloat(Number(evaluationPowerOfTen[0]).toFixed(8));
            var exponent = Number(evaluationPowerOfTen[1]);

            var result = exponent <= 9 && exponent >= -3 && base == 1 ?
                latex + "=" + parseFloat(evaluation.toFixed(roundVal))
                :
                exponent < 9 && exponent >= -3 ?
                    latex + "=" + parseFloat(evaluation.toFixed(roundVal))
                    :
                    latex + "=" + base + '\\times10^{' + exponent + '}';

            if (!result.includes('undefined') && Number(latex) !== evaluation && !error) {
                return result;
            } else {
                return latex;
            }

        };
        var expressionsArr = window.desmosCalculator.getState().expressions.list;
        var expressions = expressionsArr.reduce(
            function (acc, curVal) {
                if (typeof (acc.latex) === 'undefined') {
                    acc.push(latexSpan(curVal.latex, curVal.id));
                    return acc;
                }
                return [latexSpan(acc.latex, acc.id), latexSpan(curVal.latex, curVal.id)];
            }
        );
        if (typeof (expressions.latex) !== 'undefined') {
            expressions = [latexSpan(expressions.latex, expressions.id)];
        }

        if (typeof data_copy === 'string') {
            expressionsArr.find(function (value) {
                if (value.latex === data_copy) {
                    data_copy = latexSpan(data_copy, value.id);
                }
            });
        }

        latex2png(editor, typeof data_copy === 'string' ? [data_copy] : expressions, draggedElementUUID);
        $.getScript('/scripts/vendors/MathJax-master/MathJax.js')
            .done(function (script, textStatus) {
            })
            .fail(function (script, settings, exception, textStatus) {
                console.log('MathJax.js => ', script.statusText);
            });
    }

    execute(data) {
        if (data) {
            this.getDesmosText(this.editor, data.data);
        } else {
            this.getDesmosText(this.editor);
        }
    }
}
