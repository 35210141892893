import {Plugin} from '@ckeditor/ckeditor5-core';
import CalculatorCommand from './calculatorcommand';

export default class Calculatorediting extends Plugin {
    init() {
        this._defineSchema();
        this._defineConverters();

        this.editor.commands.add(
            'addCalculator', new CalculatorCommand(this.editor)
        );
    }

    // _defineSchema() {
    //     this.editor.model.schema.extend('imageInline', {allowAttributes: 'dSource'});
    // }

    _defineSchema() {
        const schema = this.editor.model.schema;

        schema.extend('imageInline', {
            allowAttributes: ['dSource', 'uuid','class']
        });
    }

    _defineConverters() {
        const conversion = this.editor.conversion;

        conversion.for('upcast').attributeToAttribute({
            view: 'data-source',
            model: 'dSource'
        });

        conversion.for('downcast').add(dispatcher => {
            dispatcher.on('attribute:dSource:imageInline', (evt, data, conversionApi) => {
                if (!conversionApi.consumable.consume(data.item, evt.name)) {
                    return;
                }

                const viewWriter = conversionApi.writer;
                let img = conversionApi.mapper.toViewElement(data.item);

                if (data.attributeNewValue !== null) {
                    viewWriter.setAttribute('data-source', data.attributeNewValue, img);
                } else {
                    viewWriter.removeAttribute('data-source', img);
                }
            });
        });

        conversion.for('downcast').add(dispatcher => {
            dispatcher.on('attribute:uuid:imageInline', (evt, data, conversionApi) => {
                if (!conversionApi.consumable.consume(data.item, evt.name)) {
                    return;
                }

                const viewWriter = conversionApi.writer;
                let img = conversionApi.mapper.toViewElement(data.item);

                if (data.attributeNewValue !== null) {
                    viewWriter.setAttribute('uuid', data.attributeNewValue, img);
                } else {
                    viewWriter.removeAttribute('uuid', img);
                }
            });
            dispatcher.on('attribute:class:imageInline', (evt, data, conversionApi) => {
                if (!conversionApi.consumable.consume(data.item, evt.name)) {
                    return;
                }

                const viewWriter = conversionApi.writer;
                let img = conversionApi.mapper.toViewElement(data.item);

                if (data.attributeNewValue !== null) {
                    viewWriter.setAttribute('class', data.attributeNewValue, img);
                } else {
                    viewWriter.removeAttribute('class', img);
                }
            });
        });
    }
}
