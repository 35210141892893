import { Plugin } from '@ckeditor/ckeditor5-core';
// import { WirisUi } from './wirisui';
import Wirisui from './wirisui';
import Wirisediting from './wirisediting';
import './styles.css';

export default class Wiris extends Plugin {
    static get requires() {
        return [ Wirisediting,Wirisui ];
    }
}
