import { Command } from '@ckeditor/ckeditor5-core';
import { findAttributeRange } from '@ckeditor/ckeditor5-typing';
import { toMap } from '@ckeditor/ckeditor5-utils';
import getRangeText from './utils.js';

export default class SpanCommand extends Command {
    refresh() {
        const { model } = this.editor;
        const { selection } = model.document;
        const selectionRange = selection.getFirstRange();
        const spanAttribute = selection.hasAttribute('span') ? selection.getAttribute('span') : null;
        const spanRange = spanAttribute ? findAttributeRange(selection.getFirstPosition(), 'span', spanAttribute, model) : null;

        if (selectionRange.isCollapsed) {
            this.value = spanAttribute ? {
                span: getRangeText(spanRange),
                data: spanAttribute,
                class: spanAttribute,
                range: spanRange
            } : null;
        } else {
            this.value = spanAttribute && spanRange.containsRange(selectionRange, true) ? {
                span: getRangeText(selectionRange),
                data: spanAttribute,
                class: spanAttribute,
                range: selectionRange
            } : null;
        }

        this.isEnabled = model.schema.checkAttributeInSelection(selection, 'span');
    }

    execute({ span, data }) {
        const { model } = this.editor;
        const { selection } = model.document;

        model.change(writer => {
            if (selection.isCollapsed) {
                const firstPosition = selection.getFirstPosition();
                const attributes = toMap(selection.getAttributes());
                attributes.set('span', data);

                const { end: positionAfter } = model.insertContent(
                    writer.createText(span || '', attributes),
                    this.value ? this.value.range : firstPosition
                );

                writer.setSelection(positionAfter);
                writer.removeSelectionAttribute('span');
            } else {
                const ranges = model.schema.getValidRanges(selection.getRanges(), 'span');

                for (const range of ranges) {
                    writer.setAttribute('span', data, range);
                }
            }
        });
    }
}
