import {icons, Plugin} from '@ckeditor/ckeditor5-core';
import { ButtonView } from '@ckeditor/ckeditor5-ui';
import { ContextualBalloon, clickOutsideHandler } from '@ckeditor/ckeditor5-ui';
import FormView from './tooltipview';
import getRangeText from './utils.js';
import '../styles.css';
import tooltipIcon from './ttm-small.svg';

export default class Tooltipui extends Plugin {
	static get requires() {
		return [ ContextualBalloon ];
	}

	init() {
		const editor = this.editor;

		this._balloon = this.editor.plugins.get( ContextualBalloon );
		this.formView = this._createFormView();

		editor.ui.componentFactory.add( 'tooltip', () => {
			const button = new ButtonView();

			button.label = 'Tooltip';
			button.tooltip = true;
			button.withText = true;
            button.icon = tooltipIcon;

			// Show the UI on button click.
			this.listenTo( button, 'execute', () => {
				this._showUI();
			} );

			return button;
		} );
	}

	_createFormView() {
		const editor = this.editor;
		const formView = new FormView( editor.locale );

		this.listenTo( formView, 'submit', () => {
			const value = {
				data: formView.dataInputView.fieldView.element.value
			};
			editor.execute( 'addTooltip', value );

			this._hideUI();
		} );

		this.listenTo( formView, 'cancel', () => {
			this._hideUI();
		} );

		clickOutsideHandler( {
			emitter: formView,
			activator: () => this._balloon.visibleView === formView,
			contextElements: [ this._balloon.view.element ],
			callback: () => this._hideUI()
		} );

		return formView;
	}

	_showUI() {
		const selection = this.editor.model.document.selection;

		const commandValue = this.editor.commands.get( 'addTooltip' ).value;

		this._balloon.add( {
			view: this.formView,
			position: this._getBalloonPositionData()
		} );

		if ( commandValue ) {
			this.formView.dataInputView.fieldView.value = commandValue.data;
		}
		else {
			const selectedText = getRangeText( selection.getFirstRange() );
			this.formView.dataInputView.fieldView.value = '';
		}

		this.formView.focus();
	}

	_hideUI() {
		this.formView.dataInputView.fieldView.value = '';
		this.formView.element.reset();
		this._balloon.remove( this.formView );
		this.editor.editing.view.focus();
	}

	_getBalloonPositionData() {
		const view = this.editor.editing.view;
		const viewDocument = view.document;
		let target = null;

		target = () => view.domConverter.viewRangeToDom( viewDocument.selection.getFirstRange() );

		return {
			target
		};
	}
}
