import {
	View,
	submitHandler,
} from '@ckeditor/ckeditor5-ui';

export default class FormView extends View {
	constructor( locale ) {
		super( locale );

		this.setTemplate( {
			tag: 'form',
			attributes: {
				class: [ 'ck', 'ck5-tooltip-input' ],
				tabindex: '-1'
			},
			children: this.childViews
		} );
	}

	render() {
		super.render();

		submitHandler( {
			view: this
		} );

	}

	destroy() {
		super.destroy();
	}
}
