import {icons, Plugin} from '@ckeditor/ckeditor5-core';
import { ButtonView } from '@ckeditor/ckeditor5-ui';
import { ContextualBalloon, clickOutsideHandler } from '@ckeditor/ckeditor5-ui';
import FormView from './templatesview';
import Model from "@ckeditor/ckeditor5-ui/src/model";
import Collection from '@ckeditor/ckeditor5-utils/src/collection';
import {
    addListToDropdown,
    createDropdown
} from '@ckeditor/ckeditor5-ui/src/dropdown/utils';
import SplitButtonView from '@ckeditor/ckeditor5-ui/src/dropdown/button/splitbuttonview';
import '../styles.css';

export default class Templatesui extends Plugin {
    static get requires() {
        return [ ContextualBalloon ];
    }

    init() {
        const editor = this.editor;

        this._balloon = this.editor.plugins.get( ContextualBalloon );
        this.formView = this._createFormView();

        editor.ui.componentFactory.add( 'templates', locale => {

            const dropdownView = createDropdown(locale, SplitButtonView);
            dropdownView.buttonView.actionView.set({
                withText: true,
                label: 'Templates',
                tooltip: true
            });

            const items = new Collection();

            items.add({
                type: "button",
                model: new Model({
                    withText: true,
                    label: 'Layout 1',
                    id: 'Layout1',
                    data: '<figure class="table">\n' +
                        '    <table style="background-color:hsl( 211, 100%, 96% );">\n' +
                        '        <tbody>\n' +
                        '            <tr>\n' +
                        '                <td style="background-color:hsl( 209, 100%, 96% );border:3px solid hsl(0, 0%, 100%);padding:1px;" colspan="2">\n' +
                        '                    <h2 id="e94c327adf761ddf756a8d871ba9a5cdb">\n' +
                        '                        &nbsp;\n' +
                        '                    </h2>\n' +
                        '                </td>\n' +
                        '            </tr>\n' +
                        '            <tr>\n' +
                        '                <td style="border:3px solid hsl(0, 0%, 100%);padding:1px;">\n' +
                        '                    <p style="text-align:justify;">\n' +
                        '                        &nbsp;\n' +
                        '                    </p>\n' +
                        '                </td>\n' +
                        '                <td style="border:3px solid hsl(0, 0%, 100%);padding:1px;">\n' +
                        '                    <p style="text-align:justify;">\n' +
                        '                        &nbsp;\n' +
                        '                    </p>\n' +
                        '                </td>\n' +
                        '            </tr>\n' +
                        '            <tr>\n' +
                        '                <td style="border:3px solid hsl(0, 0%, 100%);padding:1px;">\n' +
                        '                    <p style="text-align:justify;">\n' +
                        '                        &nbsp;\n' +
                        '                    </p>\n' +
                        '                </td>\n' +
                        '                <td style="border:3px solid hsl(0, 0%, 100%);padding:1px;">\n' +
                        '                    <p style="text-align:justify;">\n' +
                        '                        &nbsp;\n' +
                        '                    </p>\n' +
                        '                </td>\n' +
                        '            </tr>\n' +
                        '        </tbody>\n' +
                        '    </table>\n' +
                        '</figure>'
                })
            });

            items.add({
                type: "button",
                model: new Model({
                    withText: true,
                    label: 'Layout 2',
                    id: 'Layout2',
                    data: '<figure class="table">\n' +
                        '    <table style="background-color:hsl( 211, 100%, 96% );border:4px solid hsl(0, 0%, 100%);">\n' +
                        '        <tbody>\n' +
                        '            <tr>\n' +
                        '                <td style="background-color:hsl( 209, 100%, 96% );border:3px solid hsl(0, 0%, 100%);padding:1px;" colspan="2">\n' +
                        '                    <h2 id="e32e8f2dfc9859a0919119f5a20d66843">\n' +
                        '                        &nbsp;\n' +
                        '                    </h2>\n' +
                        '                </td>\n' +
                        '            </tr>\n' +
                        '            <tr>\n' +
                        '                <td style="border:3px solid hsl(0, 0%, 100%);padding:1px;">\n' +
                        '                    <p style="text-align:justify;">\n' +
                        '                        &nbsp;\n' +
                        '                    </p>\n' +
                        '                </td>\n' +
                        '                <td style="border:3px solid hsl(0, 0%, 100%);padding:1px;">\n' +
                        '                    <p style="text-align:justify;">\n' +
                        '                        &nbsp;\n' +
                        '                    </p>\n' +
                        '                </td>\n' +
                        '            </tr>\n' +
                        '            <tr>\n' +
                        '                <td style="border:3px solid hsl(0, 0%, 100%);padding:1px;">\n' +
                        '                    <p style="text-align:justify;">\n' +
                        '                        &nbsp;\n' +
                        '                    </p>\n' +
                        '                </td>\n' +
                        '                <td style="border:3px solid hsl(0, 0%, 100%);padding:1px;">\n' +
                        '                    <p style="text-align:justify;">\n' +
                        '                        &nbsp;\n' +
                        '                    </p>\n' +
                        '                </td>\n' +
                        '            </tr>\n' +
                        '            <tr>\n' +
                        '                <td colspan="2">\n' +
                        '                    &nbsp;\n' +
                        '                </td>\n' +
                        '            </tr>\n' +
                        '        </tbody>\n' +
                        '    </table>\n' +
                        '</figure>'
                })
            });

            items.add({
                type: "button",
                model: new Model({
                    withText: true,
                    label: 'Layout 3',
                    id: 'Layout3',
                    data: '<figure class="table">\n' +
                        '    <table style="background-color:hsl( 211, 100%, 96% );border:4px solid hsl(0, 0%, 100%);">\n' +
                        '        <tbody>\n' +
                        '            <tr>\n' +
                        '                <td style="border:3px solid hsl(0, 0%, 100%);padding:1px;text-align:justify;vertical-align:top;" rowspan="2">\n' +
                        '                    <h2 id="e603aa53a69b18d8e20fa58a437f64b31">\n' +
                        '                        &nbsp;\n' +
                        '                    </h2>\n' +
                        '                </td>\n' +
                        '                <td style="border:3px solid hsl(0, 0%, 100%);padding:1px;text-align:justify;vertical-align:top;">\n' +
                        '                    <p style="text-align:justify;">\n' +
                        '                        &nbsp;\n' +
                        '                    </p>\n' +
                        '                </td>\n' +
                        '            </tr>\n' +
                        '            <tr>\n' +
                        '                <td style="border:3px solid hsl(0, 0%, 100%);padding:1px;text-align:justify;vertical-align:top;">\n' +
                        '                    &nbsp;\n' +
                        '                </td>\n' +
                        '            </tr>\n' +
                        '            <tr>\n' +
                        '                <td style="border:3px solid hsl(0, 0%, 100%);padding:1px;text-align:justify;vertical-align:top;">\n' +
                        '                    &nbsp;\n' +
                        '                </td>\n' +
                        '                <td style="border:3px solid hsl(0, 0%, 100%);padding:1px;text-align:justify;vertical-align:top;" rowspan="2">\n' +
                        '                    &nbsp;\n' +
                        '                </td>\n' +
                        '            </tr>\n' +
                        '            <tr>\n' +
                        '                <td style="border:3px solid hsl(0, 0%, 100%);padding:1px;text-align:justify;vertical-align:top;">\n' +
                        '                    &nbsp;\n' +
                        '                </td>\n' +
                        '            </tr>\n' +
                        '        </tbody>\n' +
                        '    </table>\n' +
                        '</figure>'
                })
            });

            items.add({
                type: 'button',
                model: new Model({
                    withText: true,
                    label: 'E-Mail',
                    id: 'Mail',
                    data: '<figure class="table">\n' +
                        '    <table style="background-color:hsl( 211, 100%, 96% );border:4px solid hsl(0, 0%, 100%);">\n' +
                        '        <tbody>\n' +
                        '            <tr>\n' +
                        '                <td style="background-color:hsl(0, 0%, 100%);border:3px solid hsl(0, 0%, 100%);padding:1px;width:90px;">\n' +
                        '                    <p style="text-align:right;">\n' +
                        '                        &nbsp;\n' +
                        '                    </p>\n' +
                        '                </td>\n' +
                        '                <td style="padding:1px;">\n' +
                        '                    <p style="text-align:justify;">\n' +
                        '                        &nbsp;\n' +
                        '                    </p>\n' +
                        '                </td>\n' +
                        '            </tr>\n' +
                        '            <tr>\n' +
                        '                <td style="background-color:hsl(0, 0%, 100%);border:3px solid hsl(0, 0%, 100%);padding:1px;width:90px;">\n' +
                        '                    <p style="text-align:right;">\n' +
                        '                        &nbsp;\n' +
                        '                    </p>\n' +
                        '                </td>\n' +
                        '                <td style="border:3px solid hsl(0, 0%, 100%);padding:1px;">\n' +
                        '                    <p style="text-align:justify;">\n' +
                        '                        &nbsp;\n' +
                        '                    </p>\n' +
                        '                </td>\n' +
                        '            </tr>\n' +
                        '            <tr>\n' +
                        '                <td style="background-color:hsl(0, 0%, 100%);border:3px solid hsl(0, 0%, 100%);padding:1px;width:90px;">\n' +
                        '                    <p style="text-align:right;">\n' +
                        '                        &nbsp;\n' +
                        '                    </p>\n' +
                        '                </td>\n' +
                        '                <td style="border:3px solid hsl(0, 0%, 100%);padding:1px;">\n' +
                        '                    <p style="text-align:justify;">\n' +
                        '                        &nbsp;\n' +
                        '                    </p>\n' +
                        '                </td>\n' +
                        '            </tr>\n' +
                        '            <tr>\n' +
                        '                <td colspan="2">\n' +
                        '                    <p style="text-align:justify;">\n' +
                        '                        &nbsp;\n' +
                        '                    </p>\n' +
                        '                    <p>\n' +
                        '                        &nbsp;\n' +
                        '                    </p>\n' +
                        '                </td>\n' +
                        '            </tr>\n' +
                        '        </tbody>\n' +
                        '    </table>\n' +
                        '</figure>'
                })
            });

            addListToDropdown(dropdownView, items);

            this.listenTo( dropdownView, 'execute', evt => {
                console.log( evt.source.id, ' has been clicked' );
                const viewFragment = this.editor.data.processor.toView(  evt.source.data );
                const modelFragment = this.editor.data.toModel(  viewFragment );
                this.editor.model.insertContent(modelFragment);
            } );

            return dropdownView;
        });
    }

    _createFormView() {
        const editor = this.editor;
        const formView = new FormView( editor.locale );

        this.listenTo( formView, 'submit', () => {
            const value = {
                data: formView.dataInputView.fieldView.element.value
            };
            editor.execute( 'addTemplates', value );

            this._hideUI();
        } );

        this.listenTo( formView, 'cancel', () => {
            this._hideUI();
        } );

        clickOutsideHandler( {
            emitter: formView,
            activator: () => this._balloon.visibleView === formView,
            contextElements: [ this._balloon.view.element ],
            callback: () => this._hideUI()
        } );

        return formView;
    }

    _hideUI() {
        this.formView.dataInputView.fieldView.value = '';
        this.formView.element.reset();
        this._balloon.remove( this.formView );
        this.editor.editing.view.focus();
    }
}
