/**
 * @module special-characters/specialchar
 */
import { Plugin } from '@ckeditor/ckeditor5-core';
/**
 * A plugin that provides special characters for the "Specialchar" category.
 *
 * ```ts
 * ClassicEditor
 *   .create( {
 *     plugins: [ ..., SpecialCharacters, Specialchar ],
 *   } )
 *   .then( ... )
 *   .catch( ... );
 * ```
 */
export default class Specialchar extends Plugin {
    /**
     * @inheritDoc
     */
    static get pluginName() {
        return 'Specialchar';
    }
    /**
     * @inheritDoc
     */
    init() {
        const editor = this.editor;
        const t = editor.t;
        const plugin = editor.plugins.get('SpecialCharacters');

        const specialCharacters = [
            { title: t('alpha'), character: 'α' },
            { title: t('beta'), character: 'β' },
            { title: t('gamma'), character: 'γ' },
            { title: t('lambda'), character: 'λ' },
            { title: t('mu'), character: 'μ' },
            { title: t('rho'), character: 'ρ' },
            { title: t('omega'), character: 'Ω' },
            { title: t('pi'), character: 'π' },
            { title: t('sum'), character: '∑' },
            { title: t('delta'), character: '∆' },
            { title: t('theta'), character: 'θ' },
            { title: t('right arrow'), character: '→' },
            { title: t('left right arrow'), character: '⇌' },
            { title: t('multiplication'), character: '×' },
            { title: t('middle dot'), character: '·' },
            { title: t('division'), character: '÷' },
            { title: t('equivalent'), character: '≡' },
            { title: t('not equal'), character: '≠' },
            { title: t('approximately equal'), character: '≈' },
            { title: t('similar to'), character: '∼' },
            { title: t('infinity'), character: '∞' },
            { title: t('less than or equal to'), character: '≤' },
            { title: t('greater than or equal to'), character: '≥' },
            { title: t('plus-minus'), character: '±' },
            { title: t('square root'), character: '√' },
            { title: t('element of'), character: '∈' },
            { title: t('vertical bar'), character: '|' },
            { title: t('real numbers'), character: 'ℝ' },
            { title: t('rational numbers'), character: 'ℚ' },
            { title: t('integers'), character: 'ℤ' }
        ];

        plugin.addItems('Specialchar', specialCharacters, { label: t('Standard') });

        const arrowCharacters = [
            { title: t('leftwards simple arrow'), character: '←' },
            { title: t('rightwards simple arrow'), character: '→' },
            { title: t('upwards simple arrow'), character: '↑' },
            { title: t('downwards simple arrow'), character: '↓' },
            { title: t('leftwards double arrow'), character: '⇐' },
            { title: t('rightwards double arrow'), character: '⇒' },
            { title: t('upwards double arrow'), character: '⇑' },
            { title: t('downwards double arrow'), character: '⇓' },
            { title: t('leftwards dashed arrow'), character: '⇠' },
            { title: t('rightwards dashed arrow'), character: '⇢' },
            { title: t('upwards dashed arrow'), character: '⇡' },
            { title: t('downwards dashed arrow'), character: '⇣' },
            { title: t('leftwards arrow to bar'), character: '⇤' },
            { title: t('rightwards arrow to bar'), character: '⇥' },
            { title: t('upwards arrow to bar'), character: '⤒' },
            { title: t('downwards arrow to bar'), character: '⤓' },
            { title: t('up down arrow with base'), character: '↨' }
        ];

        plugin.addItems('Arrows', arrowCharacters, { label: t('Arrows') });
    }
}
