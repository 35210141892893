import { Plugin} from '@ckeditor/ckeditor5-core';
import {ButtonView} from '@ckeditor/ckeditor5-ui';
import FormView from './calculatorview';
import '../styles.css';
import calcIcon from './calculator.svg';

export default class Calculatorui extends Plugin {

    init() {
        const editor = this.editor;
        this.formView = this._createFormView();

        editor.ui.componentFactory.add('calculator', () => {
            const button = new ButtonView();

            button.label = 'Image from Calculator';
            button.tooltip = true;
            button.withText = false;
            button.icon = calcIcon;

            this.listenTo(button, 'execute', () => {
                editor.execute('addCalculator');
            });

            return button;
        });
    }

    _createFormView() {
        const editor = this.editor;
        const formView = new FormView(editor.locale);
        let draggedElement = null;

        this.listenTo(formView, 'submit', () => {
            const value = {
                data: null
            };
            editor.execute('addCalculator', value);
        });

        editor.editing.view.document.on('paste', (evt, data) => {
            var desmosTex = angular.element(document).injector().get('$rootScope').copyTexString;
            const dataTransfer = data.dataTransfer;
            const value = {
                data: dataTransfer.getData('text/plain')
            };
            if (dataTransfer.getData('text/plain') === desmosTex && !draggedElement) {
                editor.execute('addCalculator', value);
                evt.stop();
            }
        });

        return formView;
    }
}
