import { Plugin } from '@ckeditor/ckeditor5-core';
// import TooltipCommand from './tooltipcommand';

export default class Templatesediting extends Plugin {
    init() {
        this._defineSchema();
        this._defineConverters();
    }
    _defineSchema() {
        const schema = this.editor.model.schema;

        schema.extend( '$text', {
            allowAttributes: [ 'span' ]
        } );
    }
    _defineConverters() {
        const conversion = this.editor.conversion;

        conversion.for( 'downcast' ).attributeToElement( {
            model: 'span',

            view: (modelAttributeValue, conversionApi) => {
                const { writer } = conversionApi;

                if (modelAttributeValue === null || modelAttributeValue === undefined) {
                    return writer.createContainerElement('span');
                }

                const attributes = {
                    data: modelAttributeValue
                };

                if (modelAttributeValue && modelAttributeValue.trim) {
                    if (modelAttributeValue.trim() !== '') {
                        attributes.class = 'ck5-tooltip';
                    }
                }

                return writer.createAttributeElement('span', attributes);
            }

        } );

        // Conversion from a view element to a model attribute
        conversion.for( 'upcast' ).elementToAttribute( {
            view: {
                name: 'span',
                attributes: ['data']
            },
            model: {
                key: 'span',

                value: viewElement => {
                    const data = viewElement.getAttribute( 'data' );
                    return data;
                }
            }
        } );
    }
}
